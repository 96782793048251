module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteURL":"https://www.usdish.com"},
    },{
      plugin: require('../node_modules/@leshen/gatsby-theme-leshen/gatsby-browser.js'),
      options: {"plugins":[],"analyticsId":"GTM-N4LP2Z","brandTokens":["TND"],"mapiBrandToken":"DN","siteName":"usdish","alternateName":"usdish.com","siteURL":"https://www.usdish.com","defaultTitleTemplate":"","defaultPhone":"8552934833","phoneSymbol":"-","defaultPromoCode":"19481","smartyStreetsWebsiteKey":"","addressQualificationCode":"","convertProjectID":"10046077","vwoProjectID":"894940","datasetManagerAPIKey":"4d103f518e174ff08b22f6c9dbcab1d0","cobra":{"sitePath":"usdish.com","buttonLink":"https://www.dish.com/clearlink/packages/?tfn={ROTATING_NUMBER}&alt_rep_id=148940","buttonText":"Ordena en línea","buttonIcon":"Cart"}},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"usdish","short_name":"usdish","start_url":"/","background_color":"#E10B3B","theme_color":"#E10B3B","icon":"src/images/logo.svg","display":"browser","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1681ed8489858ad1ff5533907ab671cd"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
