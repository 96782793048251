import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from '@leshen/ui'
import siteTheme from 'gatsby-theme-dish/theme'
import '../../twilio.css'
import 'typeface-montserrat'

const Wrapper = ({ children }) => (
  <ThemeProvider theme={siteTheme}>{children}</ThemeProvider>
)

Wrapper.defaultProps = {
  theme: {},
}

Wrapper.propTypes = {
  theme: PropTypes.object,
  children: PropTypes.node.isRequired,
}

export default Wrapper
